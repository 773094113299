<template>
  <div class="section started">
    <div class="centrize full-width">
      <div class="vertical-center">
        <div class="started-content">
          <div
            class="h-title glitch-effect"
            id="headertext"
            data-text="I'm DENİZ ÖZOĞUL"
          >
            I'm <span>DENİZ ÖZOĞUL</span>
          </div>
          <div class="h-subtitle typing-subtitle">
            <p>Full-Stack Developer and Mobile Developer</p>
            <p>Amateur Photographer</p>
            <p>Based in Istanbul</p>
          </div>
          <span class="typed-subtitle"> </span>
        </div>
      </div>
    </div>
    <audio id="my_audio" src="music/sad.mp3" loop muted></audio>
  </div>
</template>
